import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import logo from "../../assets/images/footer-logo.svg";
import fb from "../../assets/images/fb.svg";
import insg from "../../assets/images/in.svg";
import tw from "../../assets/images/tw.svg";
import ldn from "../../assets/images/ldn.svg";

export default function Footer() {
  return (
    <div className="footer-section">
      <Container>
        <Row>
          <div className="d-flex align-items-center justify-content-lg-between justify-content-center mb-5">
            <div className="footer-logo">
              <Image src={logo} />
            </div>
            <div className="desktop-social-links social-links d-flex align-items-center justify-content-lg-start justify-content-center mb-4 mb-lg-0 me-5">
              <a href="#" className="social me-3">
                <Image src={insg} />
              </a>
              <a href="#" className="social me-3">
                <Image src={tw} />
              </a>
            </div>
          </div>
        </Row>
        <Row>
          <Col lg={3} xs={6}>
            <div className="mb-5 mb-lg-0">
              <h5 className="heading-5 heading-5-bold mb-4 mb-2">
                Quick Links
              </h5>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <a href="#features" className="footer-link">
                    Features
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#how-it-works" className="footer-link">
                    How it works?
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#pricing" className="footer-link">
                    Pricing
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} xs={6}>
            <div className="mb-5 mb-lg-0">
              <h5
                className="heading-5 heading-5-bold mb-4 mb-2"
                style={{ opacity: 0 }}
              >
                a
              </h5>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <a href="#contact" className="footer-link">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3}>
            <div className="mb-5 mb-lg-0">
              <h5 className="heading-5 heading-5-bold mb-4 mb-2">Legal</h5>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <a href="#terms" className="footer-link">
                    Terms And Conditions
                  </a>
                </li>
                <li className="mb-3">
                  <a href="#privacy" className="footer-link">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3}>
            <div className="mb-2 mb-lg-0">
              <div className="mobile-social-links social-links d-flex align-items-center justify-content-lg-start justify-content-center mb-4 mb-lg-0">
                <a href="#" className="social me-3">
                  <Image src={insg} />
                </a>
                <a href="#" className="social me-3">
                  <Image src={tw} />
                </a>
              </div>
              <p className="paragraph-main paragraph-main-regular text-lg-end text-center">
                © GateTeck Solutions, LLC
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
