import { Button, Form, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../assets/scss/common.scss";
import "../assets/scss/pages/login.scss";

import back from "../assets/images/back.svg";
import logo from "../assets/images/black-font-logo.svg";
import { useContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";

const ForgotPassword = () => {
    const { sendVerificationCode, validateTemporaryCode, resetPassword } = useContext(AuthContext);

    const [resetPasswordFields, setResetPasswordFields] = useState({});
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [disableBackButton, setDisableBackButton] = useState(false);
    const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
    const [isEmailInputDisabled, setIsEmailInputDisabled] = useState(false);
    const [email, setEmail] = useState(null);
    const [isCodeValidated, setIsCodeValidated] = useState(false);
    const [uniqueId, setUniqueId] = useState("");
    const [finished, setFinished] = useState(false);

    const navigate = useNavigate();
    const notifyError = (errorMessage) => toast.error(errorMessage);
    const notifySuccess = (succesMessage) => toast.success(succesMessage);

    const goBack = () => {
        navigate("/login");
    };

    const resetPasswordChange = (field, value) => {
        setResetPasswordFields({
        ...resetPasswordFields,
        [field]: value
        });
    };

    const verificationCodeChange = (field, value) => {
        setResetPasswordFields({
            ...resetPasswordFields,
            [field]: value
        });
    };

    const handleVerificationCodeSendSubmit = async (e) => {
        const formFields = {...resetPasswordFields};

        try {
        e.preventDefault();
            
        if (handleTextValidation()) {
            let userEmail = formFields['email'];

            let payload = {
            email: userEmail
            };

            setLoading(true);
            setDisableButton(true);
            setDisableBackButton(true);
            document.body.classList.add('disable-scroll');

            setEmail(userEmail);

            await sendVerificationCode(payload);

            notifySuccess("Verification sent. Please check your email.");
            setIsVerificationCodeSent(true);
            setIsEmailInputDisabled(true);
        }
        } catch (e) {
        if (e === null || e.code === "ERR_NETWORK") {
            notifyError("Website is under maintanence. Please try again later.");
        } else {
            let responseStatus = e.response.status;

            if (responseStatus === 500) {
                notifyError("Website is under maintanence. Please try again later.");
            } else if (responseStatus === 400) {
                notifyError("Invalid request. Please check your input.");
            } else if (responseStatus === 404) {
                notifyError("No account found using that email.");
            } else if (responseStatus === 429) {
                notifyError("A verification code was previously sent. Please wait 5 minutes before requesting a new one.");

                setIsVerificationCodeSent(true);
                setIsEmailInputDisabled(true);
            }
        }
        } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
        setDisableButton(true);
        setDisableBackButton(false);

        setTimeout(() => {
            setDisableButton(false);
        }, 3000);
        }
    };

    const handleVerifyTemporaryCodeSubmit = async (e) => {
        const formFields = {...resetPasswordFields};

        try {
        e.preventDefault();
            
        if (handleCodeValidation()) {
            let userEmail = email;
            let temporaryCode = formFields['code'];

            let payload = {
                email: userEmail,
                verification_code: temporaryCode
            };

            setLoading(true);
            setDisableButton(true);
            setDisableBackButton(true);
            document.body.classList.add('disable-scroll');

            const response = await validateTemporaryCode(payload);

            notifySuccess("Code successfully validated.");
            setIsCodeValidated(true);
            setUniqueId(response.data.uuid);
        }
        } catch (e) {
        if (e === null || e.code === "ERR_NETWORK") {
            notifyError("Website is under maintanence. Please try again later.");
        } else {
            let responseStatus = e.response.status;

            if (responseStatus === 500) {
                notifyError("Website is under maintanence. Please try again later.");
            } else if (responseStatus === 400) {
                notifyError("Invalid request. Please check your input.");
            } else if (responseStatus === 403) {
                notifyError("Code is expired. Please request a new one.");
            } else if (responseStatus === 401) {
                notifyError("Code is incorrect. Please try again.");
            }
        }
        } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
        setDisableButton(true);
        setDisableBackButton(false);

        setTimeout(() => {
            setDisableButton(false);
        }, 3000);
        }
    };

    const handleNewPasswordSubmit = async (e) => {
        const formFields = {...resetPasswordFields};

        try {
        e.preventDefault();
            
        if (handlePasswordValidation()) {
            let uuid = uniqueId;
            let newPassword = formFields['new-password'];
            let newPasswordConfirm = formFields['confirm-new-password'];
            let userEmail = email;
            let code = formFields['code'];

            let payload = {
                email: userEmail,
                new_password: newPassword,
                confirm_new_password: newPasswordConfirm,
                uuid: uuid,
                verification_code: code
            };

            setLoading(true);
            setDisableButton(true);
            setDisableBackButton(true);
            document.body.classList.add('disable-scroll');

            const response = await resetPassword(payload);

            setFinished(true);
        }
        } catch (e) {
        if (e === null || e.code === "ERR_NETWORK") {
            notifyError("Website is under maintanence. Please try again later.");
        } else {
            let responseStatus = e.response.status;

            if (responseStatus === 500) {
                notifyError("Website is under maintanence. Please try again later.");
            } else if (responseStatus === 400 || responseStatus === 412 || responseStatus === 406) {
                notifyError("Invalid request. Please check your input.");
            } else if (responseStatus === 403) {
                notifyError("Code is expired. Please request a new one.");
            } else if (responseStatus === 401) {
                notifyError("Session timed out. Please try again.");
            } else if (responseStatus === 404) {
                notifyError("User does not exist.");
            }
        }
        } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
        setDisableButton(true);
        setDisableBackButton(false);

        setTimeout(() => {
            setDisableButton(false);
        }, 1500);
        }
    };

    const handleTextValidation = () => {
        var specialCharacterRegex = /[!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
        var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        const formFields = {...resetPasswordFields};
        let formIsValid = true;

        if (!formFields['email']) {
            formIsValid = false;
            notifyError("Email can't be empty!");
        } else if (specialCharacterRegex.test(formFields['email'])) {
            formIsValid = false;
            notifyError("Please enter a valid email!");
        } else if (!emailRegex.test(formFields['email'])) {
            formIsValid = false;
            notifyError("Email is invalid!");
        } 

        return formIsValid;
    };

    const handleCodeValidation = () => {
        var specialCharacterRegex = /[!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
        var codeRegex = /^\d{6}$/;

        const formFields = {...resetPasswordFields};
        let formIsValid = true;

        if (!formFields['code']) {
            formIsValid = false;
            notifyError("Code can't be empty!");
        } else if (specialCharacterRegex.test(formFields['code'])) {
            formIsValid = false;
            notifyError("Please enter a valid code!");
        } else if (!codeRegex.test(formFields['code'])) {
            formIsValid = false;
            notifyError("Code is invalid!");
        } 

        return formIsValid;
    };

    const handlePasswordValidation = () => {
        var specialCharacterRegexPwd = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        var capitalLetterRegex = /[A-Z]/;

        const formFields = {...resetPasswordFields};
        let formIsValid = true;

        if (!formFields['new-password'] || !formFields['confirm-new-password']) {
            formIsValid = false;
            notifyError("Password can't be empty!");
        } else if (formFields['new-password'].length < 8) {
            formIsValid = false;
            notifyError("Password must be at least 8 characters long!");
        } else if (formFields['new-password'].length > 300) {
            formIsValid = false;
            notifyError("Password is too long!");
        } else if (!capitalLetterRegex.test(formFields["new-password"])) {
            formIsValid = false;
            notifyError("Password must have at least 1 capital letter!");
        } else if (!specialCharacterRegexPwd.test(formFields["new-password"])) {
            formIsValid = false;
            notifyError("Password must have at least 1 special character! (!@#$%^&*)");
        } else if (formFields['confirm-new-password'] && (formFields['new-password'] != formFields['confirm-new-password'])) {
            formIsValid = false;
            notifyError("Passwords do not match!");
        } 

        return formIsValid;
    };

    return (
        <div className="login-box">
        <div className="signup-login-common">
            <div className="back-btn">
            <Button className="back-btn" onClick={goBack} disabled={disableBackButton}>
                <div className="back-icon me-2">
                <Image src={back} />
                </div>
                Back
            </Button>
            </div>
            <Row className="justify-content-center p-2 p-sm-5">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="logo my-4">
                <Image src={logo} />
                </div>
                <div className="form px-2">
                <h4 className="heading-4 heading-4-semi-bold text-center mb-4">
                    Reset Your Password
                </h4>
                <div>
                    <Form>
                    { !isCodeValidated && !finished &&
                        <Form.Group className="mb-3">
                            <Form.Control type="email" placeholder="Email Address" onChange={e => resetPasswordChange('email', e.target.value)} value={resetPasswordFields['email']} disabled={isEmailInputDisabled} />
                        </Form.Group>
                    }
                    { isVerificationCodeSent && !isCodeValidated && !finished &&
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Verification Code" onChange={e => verificationCodeChange('code', e.target.value)} value={resetPasswordFields['code']} />
                        </Form.Group>
                    }
                    { isCodeValidated && !finished &&
                        <Form.Group className="mb-3">
                            <Form.Control type="password" placeholder="New Password" onChange={e => resetPasswordChange('new-password', e.target.value)} value={resetPasswordFields['new-password']} />
                        </Form.Group>
                    }
                    { isCodeValidated && !finished &&
                        <Form.Group className="mb-3">
                            <Form.Control type="password" placeholder="Confirm New Password" onChange={e => resetPasswordChange('confirm-new-password', e.target.value)} value={resetPasswordFields['confirm-new-password']} />
                        </Form.Group>
                    }
                    { !isVerificationCodeSent && !isCodeValidated && !finished &&
                        <Button
                            variant="primary"
                            type="submit"
                            className="primary-btn mb-3"
                            onClick={handleVerificationCodeSendSubmit}
                            disabled={disableButton}
                        >
                            Send Verification Code
                        </Button>
                    }
                    { isVerificationCodeSent && !isCodeValidated && !finished &&
                        <Button
                            variant="primary"
                            type="submit"
                            className="primary-btn mb-3"
                            onClick={handleVerifyTemporaryCodeSubmit}
                            disabled={disableButton}
                        >
                            Verify Code
                        </Button>
                    }
                    { isCodeValidated && !finished &&
                        <Button
                            variant="primary"
                            type="submit"
                            className="primary-btn mb-3"
                            onClick={handleNewPasswordSubmit}
                            disabled={disableButton}
                        >
                            Update Password
                        </Button>
                    }
                    { isVerificationCodeSent && !isCodeValidated && !finished &&
                        <p className="paragraph-small paragraph-small-regular text-center">
                            Please enter the code that was sent to the provided email. The code will be valid for 5 minutes. 
                        </p>
                    }
                    { !isVerificationCodeSent && !isCodeValidated && !finished &&
                        <p className="paragraph-small paragraph-small-regular text-center">
                            Please provide an email associated with your GateTeck account. A verification code will be sent to you.
                        </p>
                    }
                    { isCodeValidated && !finished &&
                        <p className="paragraph-small paragraph-small-regular text-center">
                            Please provide a new password. Your password can't be the same as your old one. 
                        </p>
                    }
                    { finished &&
                        <p className="paragraph-small paragraph-small-regular text-center">
                            Your password has been reset. Please go back to the login page and try logging in.
                        </p>
                    }
                    </Form>
                </div>
                </div>
            </div>
            </Row>
        </div>
        <ToastContainer />
        {loading && <Loading></Loading>}
        </div>
    );
};

export default ForgotPassword;
