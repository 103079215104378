import "../../assets/scss/components/templates/_goBackBtn.scss";
import { useNavigate } from "react-router-dom";

import back from "../../assets/images/back.svg";
import { Button, Image } from "react-bootstrap";

const GoBackButton = ({ isDisabled }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="back-btn p-lg-0 pt-4 pb-2">
      <Button className="back-btn" onClick={goBack} disabled={ isDisabled }>
        <div className="back-icon me-2">
          <Image src={back} />
        </div>
        Back
      </Button>
    </div>
  );
};

export default GoBackButton;
