import React from "react";
import "../assets/scss/common.scss";
import "../assets/scss/pages/home.scss";

import Hero from "../components/sections/home/hero";
import Features from "../components/sections/home/features";
import Seamless from "../components/sections/home/how-it-works";
import Pricing from "../components/sections/home/pricing";
import CTA from "../components/sections/home/cta";
import Footer from "../components/templates/footer";

function Home() {
  return (
    <div>
      <Hero />
      <Features />
      <Seamless />
      <Pricing />
      <CTA />
      <Footer />
    </div>
  );
}

export default Home;
