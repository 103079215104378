import { Button } from "react-bootstrap";

function Btn(props) {
  return (
    <Button
      className={`${props.class} position-relative`}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text}
    </Button>
  );
}

export default Btn;
