import { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "../assets/scss/common.scss";
import "../assets/scss/pages/addProperty.scss";

import DashboardSidebar from "../components/sections/dashboard/sidebar";
import Topbar from "../components/sections/dashboard/topbar";
import GoBackButton from "../components/templates/goBackBtn";
import CustomInput from "../components/templates/input";
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";

const AddProperty = ({ userFirstName }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedPropertyType, setSelectedPropertyType] = useState("apartment");
  const [placeholderText, setPlaceholderText] = useState("Enter Digits Required to Open Gate");
  const [createPropertyFields, setCreatePropertyFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);

  const { addProperty } = useContext(AuthContext);

  useEffect(() => {
    const updatePlaceholder = () => {
      if (window.innerWidth <= 600) {
        setPlaceholderText("Digits Pressed for Entry");
      } else {
        setPlaceholderText("Enter Digits Required to Open Gate");
      }
    };

    updatePlaceholder();

    window.addEventListener("resize", updatePlaceholder);

    return () => {
      window.removeEventListener("resize", updatePlaceholder);
    };
  }, []);

  const notifyError = (errorMessage) => toast.error(errorMessage);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSelectedPropertyType(event.target.value);
  };

  const handleShow = () => {
    console.log(showSidebar);

    setShowSidebar(!showSidebar);
  };

  const createPropertyChange = (field, value) => {
    setCreatePropertyFields({
        ...createPropertyFields,
        [field]: value
    });
  };

  const handleCreatePropertySubmit = async (e) => {
    const formFields = {...createPropertyFields};
    
    try {
      e.preventDefault();
      
      if (handleCreatePropertyValidation()) {
        let propertyName = formFields['property-name'];
        let callboxPhone = formFields['phone'];
        let callboxDigits = formFields['digits'];
        let propertyType = selectedPropertyType;
  
        let payload = {
            property_name: propertyName,
            callbox_phone: callboxPhone,
            callbox_digits: callboxDigits,
            property_type: propertyType
        };

        setLoading(true);
        setDisableButton(true);
        setDisableBackButton(true);
        document.body.classList.add('disable-scroll');

        await addProperty(payload);

        navigate("/properties");
        
      }
    } catch (e) {
      if (e === null || e.code === "ERR_NETWORK") {
        notifyError("Website is under maintanence. Please try again later.");
      } else {
        let responseStatus = e.response.status;

        if (responseStatus === 401) {
            notifyError("Unauthorized. Please try again.");
        } else if (responseStatus === 500 || responseStatus === 404) {
            notifyError("Website is under maintanence. Please try again later.");
        } else if (responseStatus === 406) {
            notifyError("Property limit reached. Please upgrade your tier to add more.");
        } else {
            notifyError("Website is under maintanence. Please try again later.");
        }
      }
    } finally {
        setLoading(false);
        setDisableButton(true);
        setDisableBackButton(false);
        document.body.classList.remove('disable-scroll');
        setTimeout(() => {
            setDisableButton(false);
        }, 3000);
    }
  }

  const handleCreatePropertyValidation = () => {
    var specialCharacterRegex = /[!#$%^&@.*()_+\-=\[\]{};':"\\|,<>\/?]+/;
    var phoneRegex = /^[0-9][0-9]{2}-[0-9][0-9]{2}-[0-9]{4}$/;
    var digitsRegex = /^\d+$/;

    const formFields = {...createPropertyFields};

    let formIsValid = true;

    if (!formFields['property-name']) {
        formIsValid = false;
        notifyError("Property nickname can't be empty!");
    } else if (specialCharacterRegex.test(formFields['property-name'])) {
        formIsValid = false;
        notifyError("Property nickname has invalid characters!");
    } else if (formFields['property-name'].length > 40) {
        formIsValid = false;
        notifyError("Property nickname is too long!");
    } else if (!formFields['phone']) {
        formIsValid = false;
        notifyError("Phone number can't be empty!");
    } else if (!phoneRegex.test(formFields['phone'])) {
        formIsValid = false;
        notifyError("Phone number is invalid!");
    } else if (formFields['phone'].length > 12) {
        formIsValid = false;
        notifyError("Phone number is too long!");
    } else if (!formFields['digits']) {
        formIsValid = false;
        notifyError("Digits can't be empty!");
    } else if (!digitsRegex.test(formFields['digits'])) {
        formIsValid = false;
        notifyError("Digits has invalid characters!");
    } else if (formFields['digits'].length > 10) {
        formIsValid = false;
        notifyError("Digits is too long!");
    } else if (!selectedPropertyType) {
        formIsValid = false;
        notifyError("Please select a property type!");
    } 

    return formIsValid;
  };

  if (loading)
    return <Loading></Loading>

  return (
    <div className="dashboard-body">
      <div className="d-lg-flex">
        <div>
          <DashboardSidebar
            active="properties"
            showSidebar={showSidebar}
            handleShow={handleShow}
          />
        </div>
        <div className="w-100">
          <Topbar handleShow={handleShow} firstName={userFirstName}/>
          <div className="add-property">
            <h5 className="heading-5 heading-5-semi-bold">Add Property</h5>
            <GoBackButton isDisabled={disableBackButton}/>
            <div className="add-property-box">
              <div className="add-property-content">
                <Form onSubmit={handleCreatePropertySubmit}>
                  <CustomInput
                    label="Property Nickname"
                    placeholder="Property Name"
                    type="text"
                    onChange={e => createPropertyChange('property-name', e.target.value)} value={createPropertyFields['property-name']}
                  />
                  <CustomInput
                    label="Callbox Phone Number"
                    placeholder="XXX-XXX-XXXX"
                    type="phone"
                    onChange={e => createPropertyChange('phone', e.target.value)} value={createPropertyFields['phone']}
                  />
                  <CustomInput
                    label="Callbox Digits"
                    placeholder={placeholderText}
                    type="text"
                    onChange={e => createPropertyChange('digits', e.target.value)} value={createPropertyFields['digits']}
                  />
                  <h6 className="heading-6 heading-6-semi-bold">
                    Property Type
                  </h6>
                  <Row className="radio-inputs">
                    <Col md={4}>
                      <label className="radio-label" onClick={handleChange}>
                        <input
                          type="radio"
                          className="input-radio"
                          name="propertyType"
                          value="apartment"
                          checked={selectedPropertyType === "apartment"}
                        />
                        Apartment
                      </label>
                    </Col>
                    <Col md={4}>
                      <label className="radio-label" onClick={handleChange}>
                        <input
                          type="radio"
                          className="input-radio"
                          name="propertyType"
                          value="house"
                          checked={selectedPropertyType === "house"}
                        />
                        House
                      </label>
                    </Col>
                    <Col md={4}>
                      <label className="radio-label" onClick={handleChange}>
                        <input
                          type="radio"
                          className="input-radio"
                          name="propertyType"
                          value="condo"
                          checked={selectedPropertyType === "condo"}
                        />
                        Condo
                      </label>
                    </Col>
                  </Row>
                  <div className="create-property-btn">
                    <Button variant="primary"
                      type="submit"
                      className="primary-btn mb-3"
                      disabled={disableButton}>Create Property</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddProperty;
