import { Container, Row, Col, Image } from "react-bootstrap";
import img1 from "../../../assets/images/feature-1.svg";
import img2 from "../../../assets/images/feature-2.svg";
import img3 from "../../../assets/images/feature-3.svg";
import Btn from "../../templates/button";

const Features = () => {
  return (
    <div className="features-section" id="features">
      <Container>
        <Row className="text-center text-lg-start">
          <div className="heading text-center mb-4">
            <h1 className="heading-1 heading-1-semi-bold">Our Features</h1>
          </div>
          <div>
            <Row className="align-items-center mb-5">
              <Col lg={6}>
                <div className="feature-img mb-4 mb-lg-0">
                  <Image src={img1} className="img-fluid" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="features-content ms-lg-5">
                  <div className="heading">
                    <h2 className="heading-2 heading-2-semi-bold mb-3">
                      Automated Call Routing and Verification
                    </h2>
                    <p className="paragraph-medium paragraph-medium-regular mb-3">
                      Incoming guest dial in calls are routed through GateTeck
                      where we automatically answer and validate the dial in
                      attempt. Authorized calls prompt GateTeck to dial the
                      required digits, automatically granting gate entry without
                      requiring the property owner to manually answer the call.
                    </p>
                    <a href="/signup">
                      <Btn text="Get Started" class="primary-graident-btn" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mb-5 flex-column-reverse flex-lg-row">
              <Col lg={6}>
                <div className="features-content">
                  <div className="heading">
                    <h2 className="heading-2 heading-2-semi-bold mb-3">
                      Authorized & Secure Entry Control
                    </h2>
                    <p className="paragraph-medium paragraph-medium-regular mb-3">
                      Add and manage your properties at any moment. Only
                      incoming calls from these properties’ call box phone
                      numbers are recognized by the system, ensuring that
                      unauthorized callers are ignored and access is not
                      granted.
                    </p>
                    <a href="/signup">
                      <Btn text="Get Started" class="primary-graident-btn" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="feature-img mb-4 mb-lg-0">
                  <Image src={img2} className="img-fluid" />
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="feature-img mb-lg-0">
                  <Image src={img3} className="img-fluid" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="features-content ms-lg-5">
                  <div className="heading mt-lg-0 mt-md-0 mt-4">
                    <h2 className="heading-2 heading-2-semi-bold mb-3">
                      Convenience for Property Owners and Managers
                    </h2>
                    <p className="paragraph-medium paragraph-medium-regular mb-3">
                      Free yourself from the need to answer gate-entry calls and
                      allow guests to gain entry seamlessly. The system is
                      particularly beneficial for property owners and managers,
                      such as those managing rentals or AirBnb properties.
                    </p>
                    <a href="/signup">
                      <Btn text="Get Started" class="primary-graident-btn" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Features;
