import { Image, Modal } from "react-bootstrap";

import closeBtn from "../../../assets/images/dashboard/close.svg";
import Btn from "../../templates/button";
import Loading from "../../shared/Loading";

import "../../../assets/scss/components/section/dashboard/_createSubscriptionModal.scss";
import { useContext, useState } from "react";
import AuthContext from "../../shared/AuthContext";
import { ToastContainer, toast } from 'react-toastify';

const CustomCreateSubscriptioModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const { callCreateCheckoutBasicPlan } = useContext(AuthContext);
    
    const notifyError = (errorMessage) => toast.error(errorMessage);

    const handleSubmitT1 = async (e, planId) => {
        e.preventDefault();

        try {
            setLoading(true);
            document.body.classList.add('disable-scroll');
            setDisableButton(true);

            let redirectUrl = await callCreateCheckoutBasicPlan();
            window.location.replace(redirectUrl);
        } catch(e) {
            
            if (e === null || e.code === "ERR_NETWORK" || e.response.status === null) {
                notifyError("Website is under maintanence. Please try again later.");
            } else {
                let responseStatus = e.response.status;

                if (responseStatus === 400) {
                    notifyError("You are already subscribed to this plan.");
                } else if (responseStatus === 500) {
                    notifyError("Website is under maintanence. Please try again later.");
                } 
            }
        } finally {
            setLoading(false);
            document.body.classList.remove('disable-scroll');
            setTimeout(() => {
                setDisableButton(false);
            }, 5000);
        }
    }

    if (loading)
        return <Loading></Loading>;

    return (
        <div>
            <Modal
                className="subscription-create-modal"
                show={props.showmodal}
                onHide={props.closemodal}
            >
                <div className="">
                <div className="d-flex justify-content-end" closeButton>
                    <Image
                    src={closeBtn}
                    onClick={props.closemodal}
                    className="close-btn"
                    />
                </div>
                <h3 className="modal-heading">{props.modalTitle} Tier</h3>
                <div>
                    <p className="modal-text">
                    Click below to subscribe to our {props.modalTitle} Tier <span>({props.modalPrice})</span>
                    </p>
                    <Btn class="pricing-btn" text="Subscribe with Stripe" disabled={disableButton} type="submit" onClick={handleSubmitT1}/>
                </div>
                </div>
            </Modal>
            <ToastContainer />
        </div>
    );
    };

export default CustomCreateSubscriptioModal;
