import { Form } from "react-bootstrap";
import "../../assets/scss/components/templates/_input.scss";

const CustomInput = (props) => {
  return (
    <div className="custom-input">
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        type={props.type}
        placeholder={props.placeholder}
        className="mb-3"
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
};

export default CustomInput;
