import { Row, Col, Container, Image } from "react-bootstrap";
import NavbarHome from "../../templates/navbar";

import img1 from "../../../assets/images/hero-1.svg";
import img1mobile from "../../../assets/images/hero-1-mobile.svg";
import img2 from "../../../assets/images/hero-2.svg";
import shape1 from "../../../assets/images/shape-1.svg";

const Hero = () => {
  return (
    <div className="hero-section">
      <Container>
        <div className="position-relative z-2">
          <NavbarHome />
          <Row className="hero-content">
            <Col className="text-center">
              <div className="hero-heading">
                <h1 className="heading-1 heading-1-semi-bold mx-auto">
                  Seamless Automated Gate Entry
                </h1>
                <p className="paragraph-medium paragraph-medium-regular mx-auto">
                  Automate dial in requests with our service and make gate
                  access simple and stress free
                </p>
              </div>
              <div className="hero-img-1">
                <Image src={img1} />
              </div>
              <div className="hero-img-1-mobile">
                <Image src={img1mobile} className="img-fluid" />
              </div>
            </Col>
          </Row>
          <div className="shape-1 shape z-0">
            <Image src={shape1} />
          </div>
        </div>
      </Container>
      <div className="hero-img-2 text-center">
        <Image src={img2} className="img-fluid" />
      </div>
    </div>
  );
};

export default Hero;
