import "../assets/scss/common.scss";
import "../assets/scss/pages/paymentSuccess.scss";

import logo from "../assets/images/dashboard/payment-success-logo.svg";
import check from "../assets/images/dashboard/check.svg";
import { Image } from "react-bootstrap";
import Btn from "../components/templates/button";

const PaymentSuccess = () => {
  return (
    <div className="dashboard-body">
      <div className="subscription-verification-common">
        <div className="logo">
          <Image src={logo} />
        </div>
        <div className="success-verification-box success-box">
          <div className="check-icon">
            <div className="icon">
              <Image src={check} />
            </div>
          </div>
          <h3 className="heading-3 heading-3-semi-bold">Payment Successful</h3>
          <p className="paragraph-main paragraph-main-regular">
            Thank you for becoming a paid member! We have successfully processed
            your payment.
          </p>
          <p className="paragraph-main paragraph-main-regular mb-4">
            A phone number has been provided for you and can be view using the
            button below
          </p>
          <Btn class="primary-btn" text="View Phone Number" />
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
