import DashboardSidebar from "../components/sections/dashboard/sidebar";

import "../assets/scss/common.scss";
import "../assets/scss/pages/profile.scss";

import { Button } from "react-bootstrap";

import { useState, useEffect, useContext } from "react";
import Topbar from "../components/sections/dashboard/topbar";
import { Link } from "react-router-dom";

import edit from "../assets/images/dashboard/edit.svg";
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";
import { ToastContainer, toast } from 'react-toastify';

const Profile = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [userFirstName, setUserFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const { getUserDetails } = useContext(AuthContext);

  const notifyError = (errorMessage) => toast.error(errorMessage);
  const notifySuccess = (successMessage) => toast.success(successMessage);

  useEffect(() => {
    async function getDetails() {
      try {
        setLoading(true);
        document.body.classList.add('disable-scroll');

        const userDetails = await getUserDetails();

        setUserFirstName(userDetails.data.first_name);
        setEmail(userDetails.data.email);
        setLastName(userDetails.data.last_name);
      } catch (error) {
        notifyError("Error fetching profile info. Please try again later.");
      } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
      }
    }

    getDetails();
  }, []);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isPasswordChanged = urlParams.get('passwordChanged');
    
    console.log(isPasswordChanged);
    if (isPasswordChanged === "true") {
      setShowSuccessToast(true);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (showSuccessToast) {
      notifySuccess("Your password has been successfully updated!");
      setShowSuccessToast(false);
    }
  }, [showSuccessToast]);

  const handleShow = () => {
    console.log(showSidebar);

    setShowSidebar(!showSidebar);
  };

  if (loading)
    return <Loading></Loading>;

  return (
    <div className="dashboard-body">
      <div className="d-lg-flex">
        <div>
          <DashboardSidebar
            active="profile"
            showSidebar={showSidebar}
            handleShow={handleShow}
          />
        </div>
        <div className="w-100">
          <Topbar handleShow={handleShow} firstName={userFirstName} />
          <div>
            <div className="profile-section">
              <div className="edit-profile">
                <h5 className="heading-5 heading-5-semi-bold name">
                  {userFirstName} {lastName}
                </h5>
                <h5 className="heading-5 heading-5-semi-bold mb-3">
                  Edit Profile
                </h5>
                <div className='boxes'>
                  <div className="data d-flex justify-content-between align-items-center">
                    <p>Email Address</p>
                    <p className="email">{email}</p>
                  </div>
                  <div className="data d-flex justify-content-between align-items-center">
                    <p>Change Password</p>
                    <p className="password d-flex align-items-center">
                      *****
                      <Link to="/change-password">
                        <img src={edit} alt="Edit Icon" className="edit-icon" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Profile;
