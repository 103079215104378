import { useState, useEffect, useContext } from "react";
import "../assets/scss/common.scss";
import "../assets/scss/pages/properties.scss";

import DashboardSidebar from "../components/sections/dashboard/sidebar";
import Topbar from "../components/sections/dashboard/topbar";
import { Col, Image, Nav, NavDropdown, Row } from "react-bootstrap";

import totalProperties from "../assets/images/dashboard/total-properties.svg";
import totalVisits from "../assets/images/dashboard/total-visits.svg";
import phone from "../assets/images/dashboard/phone.svg";

import apartment from "../assets/images/dashboard/apartment.svg";
import house from "../assets/images/dashboard/house.svg";
import condo from "../assets/images/dashboard/condo.svg";

import addPropertyIcon from "../assets/images/dashboard/add-property.svg";

import dotsMenu from "../assets/images/dashboard/dots-menu-icon.svg";
import { Link } from "react-router-dom";

import trash from "../assets/images/dashboard/trash.svg";
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";

const Properties = ({ userFirstName, currentPlan  }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPropertiesCount, setTotalPropertiesCount] = useState(null);
  const [totalVisitsCount, setTotalVisitsCount] = useState(null);
  const [totalDedicatedPhoneCount, setTotalDedicatedPhoneCount] = useState(null);
  const [existingProperties, setExistingProperties] = useState([]);
  const [currentProperty, setCurrentProperty] = useState({});
  const [refresh, setRefresh] = useState(false);

  const { getDashboardDetails, getExistingProperties, removeProperty } = useContext(AuthContext);

  useEffect(() => {
    async function getDetails() {
      try {
        setLoading(true);
        document.body.classList.add('disable-scroll');

        const dashboardDetails = await getDashboardDetails();
        const getPropertiesResponse = await getExistingProperties();

        setTotalPropertiesCount(dashboardDetails.data.total_properties);
        setTotalVisitsCount(dashboardDetails.data.total_visits);
        setTotalDedicatedPhoneCount(dashboardDetails.data.total_dedicated_phone_numbers);

        setExistingProperties(getPropertiesResponse.data.property_list);
      } catch (error) {
        console.log("Error getting details!");
      } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
      }
    }

    getDetails();  
  }, [refresh]);

  // Handle toggling the dropdown
  const handleToggle = (index, isOpen) => {
    setOpenDropdownIndex(isOpen ? index : null);
  };

  const handleShow = () => {
    console.log(showSidebar);

    setShowSidebar(!showSidebar);
  };

  const deleteProperty = async (e) => {
    try {
      setLoading(true);
      setDisableButton(true);

      document.body.classList.add('disable-scroll');

      let payload = {
        callbox_phone: currentProperty.callbox_phone
      };

      await removeProperty(payload);
      setRefresh(!refresh);
    } catch (error) {
      console.log("Error removing property!");
    } finally {
      setLoading(false);
      document.body.classList.remove('disable-scroll');

      setTimeout(() => {
        setDisableButton(false);
    }, 1500);
    }
  }

  const statsData = [
    {
      text: "Total Properties",
      count: totalPropertiesCount ? totalPropertiesCount + "" : "0",
      img: totalProperties,
      class: "totalProperties",
    },
    {
      text: "Total Visits",
      count: totalVisitsCount ? totalVisitsCount + "" : "0",
      img: totalVisits,
      class: "totalVisits",
    },
    {
      text: "Dedicated Phone Numbers",
      count: totalDedicatedPhoneCount ? totalDedicatedPhoneCount + "" : "0",
      img: phone,
      class: "phone",
    },
  ];

  if (loading)
    return <Loading></Loading>;

  return (
    <div className="d-lg-flex">
      <div>
        <DashboardSidebar active="properties" showSidebar={showSidebar} handleShow={handleShow}></DashboardSidebar>
      </div>
      <div className="w-100">
        <Topbar handleShow={handleShow} firstName={userFirstName} />
        <div className="properties-content">
          <div className="properties-stats">
            <h5 className="heading-5 heading-5-semi-bold">Stats</h5>
            <Row className="justify-content-center">
              {statsData.map((stat) => (
                <Col md={4} sm={6}>
                  <div className={`stats ${stat.class}`}>
                    <div className="stats-text">
                      <p className="text">{stat.text}</p>
                    </div>
                    <div className="stats-image">
                      <h2 className="count heading-1 heading-1-semi-bold">
                        {stat.count}
                      </h2>
                      <div className="img">
                        <Image src={stat.img} />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="properties">
            <div className="properties-heading">
              <h5 className="heading-5 heading-5-semi-bold">Properties</h5>
              <Nav.Link
                className="add-property-btn"
                as={Link}
                to="/add-property"
                disabled={disableButton}
              >
                <Image src={addPropertyIcon} />
                Add Property
              </Nav.Link>
            </div>
            <div className="properties-boxes">
              <Row className="justify-content-center">
                {existingProperties.map((prop, index) => (
                  <Col md={4} sm={6} key={index}>
                    <div className="box">
                      <div className="box-top">
                        <div className={`icon ${prop.property_type === "apt" ? "Apartment" : (prop.property_type === "house" ? "House" : "Condo")}`}>
                          <Image src={prop.property_type === "apt" ? apartment : (prop.property_type === "house" ? house : condo)} />
                          <p>{prop.property_type === "apt" ? "Apartment" : (prop.property_type === "house" ? "House" : "Condo")}</p>
                        </div>
                        <div className="dots">
                          <NavDropdown
                            title={
                              <>
                                <Image src={dotsMenu} className="ms-1" />
                              </>
                            }
                            menuVariant="dark"
                            show={openDropdownIndex === index}
                            onToggle={(isOpen) => {
                              setCurrentProperty(prop);
                              handleToggle(index, isOpen)}}
                          >
                            <NavDropdown.Item
                              className="delete"
                              onClick={deleteProperty}
                              disabled={disableButton}
                            >
                              <span>
                                <Image src={trash} className="me-2" />
                              </span>
                              Delete
                            </NavDropdown.Item>
                          </NavDropdown>
                        </div>
                      </div>
                      <h5 className="heading-6">{prop.property_name}</h5>
                      <p className="count">{prop.callbox_phone}</p>
                      <p className="count">Digits to be pressed: {prop.callbox_digits}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Properties;
