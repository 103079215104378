import { Col, Container, Image, Row, Button } from "react-bootstrap";

import check from "../../../assets/images/check.svg";

const Pricing = () => {
  const pricing_content = [
    {
      id: 1,
      name: "Basic Tier",
      description: "Ideal for the everyday property owner ",
      list: {
        item1: "Up To 1 Property Supported",
        item2: "Generic Welcome Message",
        item3: "1 Dedicated Phone Number",
      },
      price: "$10.00",
      btn_text: "Get Started",
    },
    {
      id: 2,
      name: "Host Tier",
      description: "Ideal for casual renters or AirBnb hosts",
      list: {
        item1: "Coming Soon",
        item2: "Coming Soon",
        item3: "Coming Soon",
      },
      price: "$--.--",
      btn_text: "Coming Soon",
    },
    {
      id: 3,
      name: "Entrepreneur Tier",
      description: "Ideal for real estate groups or property moguls",
      list: {
        item1: "Coming Soon",
        item2: "Coming Soon",
        item3: "Coming Soon",
      },
      price: "$--.--",
      btn_text: "Coming Soon",
    },
  ];
  return (
    <div className="pricing-section" id="pricing">
      <Container>
        <Row>
          <Col className="text-center heading">
            <h1 className="heading-1 heading-1-semi-bold">Pricing Plans</h1>
          </Col>
        </Row>
        <Row>
          {pricing_content.map((content) => (
            <Col lg={4} className="mb-4 mb-lg-0">
              <div
                className={`pricing-box d-flex justify-content-between flex-column text-center ${
                  content.name === "Host Tier" ? "middle" : ""
                }`}
              >
                <div className="content">
                  <h4 className="heading-4 heading-4-semi-bold">
                    {content.name}
                  </h4>
                  <p className="paragraph-main paragraph-main-regular mb-5">
                    {content.description}
                  </p>
                  <div className="listItems text-start">
                    <div className="d-flex align-items-center mb-3">
                      <div className="check me-3 d-flex align-items-center justify-content-center">
                        <Image src={check} />
                      </div>
                      <p className="mb-0 paragraph-small paragraph-small-regular">
                        {content.list.item1}
                      </p>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <div className="check me-3 d-flex align-items-center justify-content-center">
                        <Image src={check} />
                      </div>
                      <p className="mb-0 paragraph-small paragraph-small-regular">
                        {content.list.item2}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="check me-3 d-flex align-items-center justify-content-center">
                        <Image src={check} />
                      </div>
                      <p className="mb-0 paragraph-small paragraph-small-regular">
                        {content.list.item3}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="price">
                  <h1 className="heading-1 heading-1-semi-bold">
                    {content.price}
                  </h1>
                  <a href="/signup">
                    <Button
                      className={`heading-6 heading-6-bold ${
                        content.name !== "Basic Tier"
                          ? "pricing-btn-light"
                          : "pricing-btn"
                      }`}
                    >
                      {content.btn_text}
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Pricing;
