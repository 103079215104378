import { Container, Row, Col, Image } from "react-bootstrap";
import img1 from "../../../assets/images/hashtag.svg";
import img2 from "../../../assets/images/numbers.svg";
import img3 from "../../../assets/images/settings.svg";

const Seamless = () => {
  const seamless_content = [
    {
      id: 1,
      img: img1,
      serial: 1,
      heading: "Provide Your Gate's Call Box Number",
      subHeading:
        "Share your gate's call box number and the required phone digits for gate access.",
    },
    {
      id: 2,
      img: img2,
      serial: 2,
      heading: "Receive Your Dedicated Phone Number",
      subHeading:
        "We'll provide you with a dedicated phone number. This number will be called by the call box in place of your own phone number.",
    },
    {
      id: 3,
      img: img3,
      serial: 3,
      heading: "Update Your Call Box Settings",
      subHeading:
        "Update your call box contact to use your provided GateTeck phone number.",
    },
  ];
  return (
    <div className="seamless-section" id="how-it-works">
      <Container>
        <div className="heading text-center mb-5">
          <h1 className="heading-1 heading-1-semi-bold">
            Enjoy seamless gate access in 3 easy steps
          </h1>
        </div>
        <Row>
          {seamless_content.map((content) => (
            <Col lg={4} index={content.id}>
              <div
                className={`seamless-box ${
                  content.serial === 2 ? "mt-4 mb-4" : ""
                }`}
              >
                <div className="top d-flex align-items-center justify-content-between mb-4">
                  <div>
                    <Image src={content.img} />
                  </div>
                  <p className="serial heading-1 heading-1-semi-bold mb-0">
                    {content.serial}
                  </p>
                </div>
                <div className="seamless-text">
                  <h3 className="heading-5 heading-5-semi-bold mb-3">
                    {content.heading}
                  </h3>
                  <p className="paragraph-main paragraph-main-regular">
                    {content.subHeading}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Seamless;
