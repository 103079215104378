import { createContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthContextProvider = ({children}) => {
    const navigate = useNavigate();

    const loginApiCall = async (payload) => {
        const loginApiResponse = await axios.post(process.env.REACT_APP_DOMAIN_URL + "v1/user/login", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        if (loginApiResponse.data.status === "Fail") {
            ; // Do nothing!
        } else {
            const apiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user", {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (apiResponse.data.status === "Fail") {
                ; // Do nothing!
            }
            else {
                if (apiResponse.data.verified_user) {
                    navigate("/dashboard");
                } else {
                    navigate("/email-verification");
                }
            }
        }
    };

    const createAccountApiCall = async(payload) => {
        const createAccountApiResponse = await axios.post(process.env.REACT_APP_DOMAIN_URL + "v1/user/create", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        if (createAccountApiResponse.data.status === "Fail") {
            ; // Do nothing!
        } else {
            const apiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user", {
                withCredentials: true
            });

            if (apiResponse.data.status === "Fail") {
                ; // Do nothing
            }
            else {
                navigate("/email-verification");
            }
        }
    };

    const sendVerificationToken = async() => {
        const sendVerificationTokenApiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user/authentication/token/send", {
            withCredentials: true
        });

        return sendVerificationTokenApiResponse;
    };

    const verifyToken = async(uuid) => {
        const verifyVerificationToken = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user/authentication/token/verify/" + uuid, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json'
            }
        });

        return verifyVerificationToken;
    };

    const isUserVerified = async () => {
        const isUserVerifiedApiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user/status", {
            withCredentials: true,
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        });

        if (!isUserVerifiedApiResponse.data.status)
            return false;

        return true;
    };

    const signOutUser = async () => {
        const signOutUserApiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user/logout", {
            withCredentials: true
        });

        return signOutUserApiResponse;
    };

    const getFirstName = async () => {
        const getFirstNameApiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user/name", {
            withCredentials: true
        });

        return getFirstNameApiResponse.data.first_name;
    };

    const changePassword = async (payload) => {
        const changePasswordApiResponse = await axios.post(process.env.REACT_APP_DOMAIN_URL + "v1/password", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        return changePasswordApiResponse;
    };

    const getEmail = async () => {
        const getEmailApiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user/email", {
            withCredentials: true
        });

        return getEmailApiResponse.data.email;
    };

    const getCurrentPlan = async () => {
        const getEmailApiResponse = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user/plan", {
            withCredentials: true
        });

        return getEmailApiResponse.data.plan_name;
    };

    const getUserDetails = async () => {
        const userDetails = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/user", {
            withCredentials: true
        });

        return userDetails;
    };

    const getDashboardDetails = async () => {
        const userDetails = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/dashboard/details", {
            withCredentials: true
        });

        return userDetails;
    };

    const getExistingProperties = async () => {
        const getPropertiesApiCall = await axios.get(process.env.REACT_APP_DOMAIN_URL + "v1/properties", {
            withCredentials: true,
        });

        return getPropertiesApiCall;
    };

    const addProperty = async (payload) => {
        const addPropertyApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "v1/property", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        return addPropertyApiCall;        
    };

    const removeProperty = async (payload) => {
        const removePropertyApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "v1/property/remove", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        return removePropertyApiCall;      
    };

    const callCreateCheckoutBasicPlan = async () => {
        const removePropertyApiCall = await axios.get(process.env.REACT_APP_DOMAIN_URL + "billing/v1/checkout/session/basic", {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
            }
        });   

        return removePropertyApiCall.data;
    };

    const submitMessage = async (payload) => {
        const submitMessageApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "v1/user/message", JSON.stringify(payload), {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        return submitMessageApiCall;      
    };

    const sendVerificationCode = async (payload) => {
        const sendVerificationCodeApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "v1/password/reset/verify", JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        return sendVerificationCodeApiCall;      
    };

    const validateTemporaryCode = async (payload) => {
        const validateTemporaryCodeApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "v1/password/reset/code", JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        return validateTemporaryCodeApiCall;      
    };

    const resetPassword = async (payload) => {
        const resetPasswordApiCall = await axios.post(process.env.REACT_APP_DOMAIN_URL + "v1/password/reset", JSON.stringify(payload), {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        return resetPasswordApiCall;
    };

    return <AuthContext.Provider value={{
            loginApiCall, createAccountApiCall, sendVerificationToken, verifyToken, isUserVerified, 
            signOutUser, getFirstName, changePassword, getEmail, getCurrentPlan, getUserDetails, getDashboardDetails, getExistingProperties, 
            addProperty, removeProperty, callCreateCheckoutBasicPlan, submitMessage, sendVerificationCode, validateTemporaryCode, resetPassword
            }}> 
                {children}
            </AuthContext.Provider>
}


export default AuthContext;